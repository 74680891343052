import * as React from 'react'
import { useEffect } from 'react'
import { Alert, Button, Card, Col, Modal, Placeholder, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsFillXCircleFill } from 'react-icons/bs'
import FileMgDS from '../../../Services/Utils/MFileManager.Service'
import { AxiosError } from 'axios'

export interface IPictureProps {
  id: number
  proceso: number
  src: string
  fecha: string
  size: number
  activo: number
  onToggleStatus: (id: number) => void
}

export default function Picture(props: IPictureProps) {
  const [loaded, setLoaded] = React.useState(false)
  const [ID, setID] = React.useState(0)
  const [ModalDelete, setModalDelete] = React.useState(false)
  const [ModalImagen, setModalImagen] = React.useState(false)

  useEffect(() => {
    console.log(props)
  }, [])

  const togglePicture = (id: number) => {
    setModalDelete(false)
    FileMgDS.togglePicture(id)
      .then((response) => {
       props.onToggleStatus(props.id)
      })
      .catch((e: AxiosError) => {})
  }

  return (
    <div className='box'>
      {
        <div>
          <Card style={{ height: '100%' }}>
            <Card.Header as='h6'>
              <Row>
                <Col xs={10}>{props.fecha} </Col>
                <Col
                  xs={2}
                  style={{
                    visibility: props.size < 1000 ? 'visible' : 'hidden',
                  }}
                >
                  <div
                    onClick={() => {
                      setID(props.id)
                      setModalDelete(true)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <IconContext.Provider
                      value={{ color: 'red', size: '15px' }}
                    >
                      <BsFillXCircleFill />
                    </IconContext.Provider>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            {!loaded ? (
              <Placeholder
                xs={12}
                as='p'
                size='lg'
                animation='wave'
                bg='dark'
                style={{ height: props.size }}
              />
            ) : null}
            <img
              src={props.src}
              style={{
                cursor: 'pointer',
                opacity: props.activo == 1 ? '1' : '0.5',
                border:
                  props.activo == 1 ? '5px solid transparent' : '5px solid red',
                visibility: !loaded ? 'hidden' : 'visible',
              }}
              onLoad={() => setLoaded(true)}
              onClick={()=>{
                setModalImagen(true)
              }}
            />
          </Card>
        </div>
      }
      <Modal show={ModalImagen} onHide={() => setModalImagen(false)} size='xl'>
        <Modal.Header closeButton />
        <Modal.Body style={{ textAlign: 'center' }}>
          <Picture
            src={`https://warehouse.gemcousa.solutions/WAREHOUSEBackend/api/Utils/MFileManager/GetFileContentById?id=${props.id}&Proceso=${props.proceso}&Size=1`}
            fecha={''}
            proceso={props.proceso}
            id={props.id}
            activo={1}
            size={1024}
            onToggleStatus={()=>{}}
          ></Picture>
        </Modal.Body>
      </Modal>
      <Modal show={ModalDelete} onHide={() => setModalDelete(false)} size='lg'>
        <Modal.Header closeButton />
        <Modal.Body>
          <Alert variant='warning' style={{ fontSize: '25px' }}>
            ¿Esta seguro de {props.activo == 1 ? 'eliminar' : 'reactivar'} esta foto?
          </Alert>
          <Row>
            <Col>
              <Button
                onClick={() => {
                  setModalDelete(false)
                }}
              >
                No
              </Button>
            </Col>
            <Col xs={9}></Col>
            <Col>
              <Button
                variant={props.activo == 1 ? 'danger' : 'success'}
                onClick={() => {
                  togglePicture(ID)
                }}
              >
                {props.activo == 1 ? 'Elimina' : 'Reactiva'}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}
