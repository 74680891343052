import * as React from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import FileMgDS from '../../../Services/Utils/MFileManager.Service'
import axios, { AxiosError } from 'axios'
import IFileManager from '../../../Interfaces/Utils/IFileManager'
import { Buffer } from 'buffer'
import '../../.././css/generic01.css'
import Picture from '../Picture/Picture'

export interface IViewerProps {}

export default function Viewer(props: IViewerProps) {
  const [NoCaja, setNoCaja] = React.useState('')
  const [Referencia, setReferencia] = React.useState('')
  const [OrdenCarga, setOrdenCarga] = React.useState('')
  const [Guia, setGuia] = React.useState('')
  const [Data, setData] = React.useState<IFileManager[]>([])
  const [Proceso, setProceso] = React.useState(0)
/*   const [IdTotal, setIdTotal] = React.useState(0)
  const [ProcesoTotal, setProcesoTotal] = React.useState(0) */

  const search = () => {
    let Tags = ''
    let Proceso = 0
    if (NoCaja != '') {
      Proceso = 1
      Tags = 'Numero de Caja=' + NoCaja
    }
    if (Referencia != '') {
      Proceso = 2
      Tags = 'Referencia=' + Referencia
    }
    if (OrdenCarga != '') {
      Proceso = 4
      Tags = 'OrdenCarga=' + OrdenCarga
    }
    if (Guia != '') {
      Proceso = 5
      Tags = 'Guia=' + Guia
    }
    setProceso(Proceso)
    FileMgDS.Get(Tags, Proceso)
      .then((response) => {
        setData(response.data)
        console.log(response.data)
      })
      .catch((e: AxiosError) => {})
  }

  return (
    <div>
      <Row>
        <Card style={{ width: '100%' }}>
          <Card.Body>
            <Card.Text>
              <Row>
                <Col style={{ textAlign: 'right' }}>Numero de caja</Col>
                <Col>
                  <Form.Control
                    type='text'
                    id='NoCaja'
                    value={NoCaja}
                    onChange={(evt) => {
                      setNoCaja(evt.target.value)
                    }}
                    aria-describedby=''
                  />
                </Col>
                <Col style={{ textAlign: 'right' }}>Referencia</Col>
                <Col>
                  <Form.Control
                    type='text'
                    id='Referencia'
                    value={Referencia}
                    onChange={(evt) => {
                      setReferencia(evt.target.value)
                    }}
                    aria-describedby=''
                  />
                </Col>
                <Col style={{ textAlign: 'right' }}>Orden de carga</Col>
                <Col>
                  <Form.Control
                    type='text'
                    id='OrdenCarga'
                    value={OrdenCarga}
                    onChange={(evt) => {
                      setOrdenCarga(evt.target.value)
                    }}
                    aria-describedby=''
                  />
                </Col>
                <Col style={{ textAlign: 'right' }}>Guia</Col>
                <Col>
                  <Form.Control
                    type='text'
                    id='Guia'
                    value={Guia}
                    onChange={(evt) => {
                      setGuia(evt.target.value)
                    }}
                    aria-describedby=''
                  />
                </Col>
                <Col>
                  <Button
                    variant='primary'
                    onClick={() => {
                      search()
                    }}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
      <Container>
        <Row
          xs={1}
          md={5}
          className='g-4'
          style={{ paddingTop: '10px', paddingBottom: '40px' }}
        >
          {Data ? (
            Data.map((item: IFileManager) => {
              return (
                <Col
                  key={item.id}
                  style={{ paddingTop: '10px', paddingBottom: '20px' }}
                >
                  <div>
                    <Picture
                      src={`https://warehouse.gemcousa.solutions/WAREHOUSEBackend/api/Utils/MFileManager/GetFileContentById?id=${item.id}&Proceso=${item.proceso}&Size=2`}
                      fecha={item.fechaRegistro}
                      proceso={item.proceso}
                      id={item.id}
                      activo={item.activo}
                      size={170}
                      onToggleStatus={function (id: number): void {
                        search()
                      }}
                    ></Picture>
                  </div>
                </Col>
              )
            })
          ) : (
            <></>
          )}
        </Row>
      </Container>
    </div>
  )
}
