import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Viewer from './Viewer/Viewer'


interface IProps {}

export default function Dashboard(props: IProps) {

  return (
    <div>
      <br />
      <Container>
        <Row>
          <Viewer/>
        </Row>
      </Container>
    </div>
  )
}
